import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Events from "../events/Events";
import CreateEvent from "../create/CreateEvent";
import Event from "../event/Event";
import NotFound from "../notfound/NotFound";
import Layout from "../../components/layout/Layout";
import { useAuth } from '../../authentication/Auth';
import { connect } from "react-redux";

const mapStateToProps = state => ({
    eventError: state.event.error
});

const App = (props) => {
    const { authState, signOut, signInWithProviders } = useAuth();
    if (authState.loading || authState.status === "loading") {
        return null;
    }    
    return (<Layout>
                    <BrowserRouter>
        {authState.status === "in" ? 
            <main>
                { !props.eventError.state ? 
                <Switch>
                    <Route exact path="/" component={Events} />
                    <Route exact path="/events" component={Events} />
                    <Route exact path="/events/create" component={CreateEvent} />
                    <Route exact path="/events/:eventId" component={Event} />
                    <Route component={NotFound} />
                </Switch> : 
                <div>
                    <h3>You are not authorised to be here</h3>
                    <p>Switch to authorised accound or contact support</p>
                    <button className="btn btn-secondary" onClick={() => signOut()}>Log out</button>
                </div>}
            </main> 
            : 
            signInWithProviders()
        }
        </BrowserRouter>

    </Layout>
)};

export default connect(mapStateToProps)(App);


//Snabbknappar vad skannar jag
//Hjäl
//Chassi
//motor
//Cylinder

//Däck
//-slicks ange antal däck (4)
//-Regn
//Övrigt
