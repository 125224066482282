import { isEqual } from "lodash";
import React, { Component } from "react";
import EventDetails from './../eventDetails/EventDetails';

import {
    Row,
    Col
} from "reactstrap";
let socket;
class Event extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const eventId = this.props.match.params.eventId;
        this.props.fetchEvent(eventId);
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps, this.props);
    }

    componentWillUnmount() {
    }

    render() {
        const {
            event
        } = this.props;
        return (
            <EventDetails event={event} />
        );
    }
}

export default Event;
