import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import { useAuth } from '../../authentication/Auth';

const Navigation = (props) => {
    const [isOpen, setNavigationIsOpen] = useState(false);
    const { authState, signOut, signInWithProviders } = useAuth();
    return (
        <React.Fragment>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/">Scorit admin</NavbarBrand>
                {
                    authState.status === "in" && <React.Fragment>
                        <NavbarToggler onClick={() => setNavigationIsOpen(!isOpen)} />

                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink tag={Link} to="/events">
                                        Events
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/events/create">
                                        Create event
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    <NavLink tag={Link} to="#" onClick={() => signOut()}>
                                        Logout
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </React.Fragment>
                }
            </Navbar>
        </React.Fragment>
    );
};

// class Navigation extends Component {
//     constructor(props) {
//         super(props);

//         this.toggle = this.toggle.bind(this);
//         this.state = {
//             isOpen: false
//         };
//     }
//     toggle() {
//         this.setState({
//             isOpen: !this.state.isOpen
//         });
//     }
//     render() {
//         return (
//             <React.Fragment>
//                 <Navbar color="light" light expand="md">
//                     <NavbarBrand href="/">Scorit admin</NavbarBrand>
//                     <NavbarToggler onClick={this.toggle} />
//                     <Collapse isOpen={this.state.isOpen} navbar>
//                         <Nav className="mr-auto" navbar>
//                             <NavItem>
//                                 <NavLink tag={Link} to="/events">
//                                     Events
//                                 </NavLink>
//                             </NavItem>
//                             <NavItem>
//                                 <NavLink tag={Link} to="/events/create">
//                                     Create event
//                                 </NavLink>
//                             </NavItem>
//                         </Nav>
//                     </Collapse>
//                 </Navbar>
//             </React.Fragment>
//         );
//     }
// }

export default Navigation;
