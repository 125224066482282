//import React from 'react'
//import { push } from 'react-router-redux'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Events from "./../../components/events/Events";
import {
    fetchEvents,
    fetchEventsSorted,
    updateConnectedClients
} from "../../modules/event";

const mapStateToProps = state => ({
    events: state.event.events,
    pastEvents: state.event.pastEvents,
    currentEvents: state.event.currentEvents,
    futureEvents: state.event.futureEvents,
    connectedClients: state.event.connectedClients
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchEvents,
            fetchEventsSorted,
            updateConnectedClients
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Events);
