import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
//import { routerReducer } from "react-router-redux";
import event from "./event";

// export default combineReducers({
//     routing: routerReducer,
//     event
// });

export default (history) => combineReducers({
    router: connectRouter(history),
    event
});