//import React from 'react'
//import { push } from 'react-router-redux'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CreateEvent from "./../../components/create/CreateEvent";
import { fetchEvents, fetchEventsSorted, addEvent, resetCreatedEvent } from "../../modules/event";

const mapStateToProps = state => ({
    events: state.event.events,
    pastEvents: state.event.pastEvents,
    currentEvents: state.event.currentEvents,
    futureEvents: state.event.futureEvents,
    createdEvent: state.event.createdEvent
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchEvents,
            fetchEventsSorted,
            addEvent,
            resetCreatedEvent
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
