import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID
});

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

export default function Auth({ children }) {
    const [authState, setAuthState] = useState({ status: 'loading' });
    useEffect(() => {
        return firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const token = await user.getIdToken();
                const idTokenResult = await user.getIdTokenResult();
                setAuthState({ status: 'in', user, token });

            } else {
                setAuthState({ status: 'out' });
            }
        });
    }, []);

    const signOut = async () => {
        try {
            setAuthState({ status: 'loading' });
            await firebase.auth().signOut();
            setAuthState({ status: 'out' });
        } catch (error) {
            console.log(error);
        }
    };

    const signInWithProviders = (asd) => {
        const uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'popup',
            // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
            signInSuccessUrl: '/',
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // firebase.auth.FacebookAuthProvider.PROVIDER_ID
            ]
        };

        return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />;
    };

    return (
        <AuthContext.Provider
            value={{
                authState,
                signOut,
                signInWithProviders
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
