//import React from 'react'
//import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import NotFound from './../../components/notFound/NotFound'

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotFound)
