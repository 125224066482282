//import React from 'react'
//import { push } from 'react-router-redux'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Event from "./../../components/event/Event";
import { fetchEvent } from "../../modules/event";

const mapStateToProps = state => ({
    event: state.event.event
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchEvent
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Event);
