import React from "react";
import {
    Row,
    Col,
} from "reactstrap";

const EventDetails = ({ event }) => {
    const tvUrl = `https://www.scorit.live/tv/${event.externalId}/1920/1080`;
    const liveUrl = `https://www.scorit.live/live/${event.externalId}`;
    const eventUrl = `https://www.scorit.live/events/${event.externalId}`;
    const raceAdminUrl = `https://race-admin.scorit.se/${event.collectorId}`;
    const collectorId = `${event.collectorId}`;
    const externalId = `${event.externalId}`;

    if(event.id == undefined)
        return null;
    return (
        <Row>
            <Col>
                <h1>{event.eventName}</h1>
                <h3>{event.trackName}</h3>
                <div><a href={eventUrl} target="blank">{eventUrl}</a></div>
                <div><a href={liveUrl} target="blank">{liveUrl}</a></div>
                <div><a href={tvUrl} target="blank">{tvUrl}</a></div>
                <div><a href={raceAdminUrl} target="blank">{raceAdminUrl}</a></div>
                <div>Public event id: <div>{externalId}</div></div>
                <div>Admin event id: <div>{collectorId}</div></div>
            </Col>
            <Col>
                Hi 
                <br />
                To use scorit, you must download and install the attached program. After installation, start the program if it does not happen automatically, feel free to pin it in the taskbar or menu as I have not added it to the installation yet.
                <br />
                <br />
                In the program, enter the IP of the orbits computer (localhost if it is the same computer that the orbits is running on) and port 50000 (orbits 4) 60000 (orbits 5) and the event time that is attached to the email after that, press connect. Once the program has connected itself to orbits and my server, it is possible to press start, after which the program will start sending data to my server.                <br />
                <br />
                <br />
                Attached bellow is a more comprehensive guide on how to use the Collector and how to arrange fields in Orbits 
                <br />
                OBS
                All runs that have laps must be set to automatic finish on lap or individual finish on lap. This is to give a better experiance to the users
                <br />
                <br />
                EventID: {event.collectorId}
                <br />
                Collector: http://download.scorit.se/scorit-collector.zip
                Guide: https://assets.scorit.live/how-to-use.pdf
                <br />
                <br />
                Länk till eventet
                <br />
                {eventUrl}
            </Col>
        </Row>
    );
};

export default EventDetails;
