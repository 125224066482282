import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from 'connected-react-router'
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import { createBrowserHistory } from 'history';
import createRootReducer from "./modules";
export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [thunk, promiseMiddleware(), routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === "function") {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(createRootReducer(history), initialState, composedEnhancers);

export default store;
