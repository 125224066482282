import React, { Component } from "react";
import { Container } from "reactstrap";
import Navigation from "./Navigation";

class Layout extends Component {
    render() {
        return (
            <div className="site-bg">
                <Navigation />
                <Container>{this.props.children}</Container>
            </div>
        );
    }
}

export default Layout;
