import React, { Component } from "react";
import { Container, Row } from "reactstrap";

class Events extends Component {
    render() {
        return (
            <div className="site-bg not-found">
                <Container>
                    <Row>
                        <div className="col-12 icon">
                            4<i
                                className="fa fa-life-ring fa-spin fa-fw"
                                aria-hidden="true"
                            />4
                        </div>
                        <div className="col-12 text">No race here</div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Events;
