import { indexOf } from "lodash";
import Api from "./../api/";
export const FETCH_EVENTS = "event/FETCH_EVENTS";
export const FETCH_EVENT = "event/FETCH_EVENT";
export const ADD_EVENT = "event/ADD_EVENT";
export const FETCH_SORTED_EVENTS = "event/FETCH_SORTED_EVENTS";
export const UPDATE_CONNECTED_CLIENTS = "event/UPDATE_CONNECTED_CLIENTS";
export const RESET_CREATED_CLIENT = "event/RESET_CREATED_CLIENT";

const initialState = {
    events: [],
    event: {},
    pastEvents: [],
    currentEvents: [],
    futureEvents: [],
    connectedClients: {},
    createdEvent: {},
    error: {
        state: false,
        message: ""
    }
};

export default (state = initialState, action) => {
    switch (true) {
        case action.type === `${FETCH_EVENTS}_FULFILLED`:
            return {
                ...state,
                pastEvents: action.payload.pastEvents,
                currentEvents: action.payload.currentEvents,
                futureEvents: action.payload.futureEvents
            };
        case action.type === `${FETCH_EVENT}_FULFILLED`:
            return {
                ...state,
                event: action.payload.event,
            };
        case action.type === `${ADD_EVENT}_FULFILLED`:
            return {
                ...state,
                createdEvent: action.payload.result
            };
        case action.type === `${FETCH_SORTED_EVENTS}_FULFILLED`:
            return {
                ...state,
                pastEvents: action.payload.pastEvents,
                currentEvents: action.payload.currentEvents,
                futureEvents: action.payload.futureEvents
            };
        case action.type === UPDATE_CONNECTED_CLIENTS:
            return {
                ...state,
                connectedClients: action.payload
            };
        case action.type === RESET_CREATED_CLIENT:
            return {
                ...state,
                connectedClients: action.payload
            };
        case action.type.indexOf("_REJECTED") > -1:{
            return {
                ...state,
                error: { state: true, message: action.payload.message }
            };
        }
            
        default:
            return state;
    }
};
export const addEvent = event => ({
    type: ADD_EVENT,
    payload: Api.addEvent(event)
});
export const fetchEvents = () => ({
    type: FETCH_EVENTS,
    payload: Api.fetchEvents()
});
export const fetchEventsSorted = () => ({
    type: FETCH_SORTED_EVENTS,
    payload: Api.fetchEventsSorted()
});
export const updateConnectedClients = connectedClients => ({
    type: UPDATE_CONNECTED_CLIENTS,
    payload: connectedClients
});
export const resetCreatedEvent = () => ({
    type: RESET_CREATED_CLIENT,
    payload: {}
});
export const fetchEvent = eventId => ({
    type: FETCH_EVENT,
    payload: Api.fetchEvent(eventId)
});
