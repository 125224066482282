import { isEqual } from "lodash";
import React, { Component } from "react";
import moment from "moment";
import ioClient from "socket.io-client";
import { Link } from "react-router-dom";

import {
    CardTitle,
    Card,
    CardColumns,
    CardSubtitle,
    CardHeader,
    CardFooter,
    CardBody,
    Badge,
    Row,
    Col
} from "reactstrap";
let socket;
class Events extends Component {
    constructor(props) {
        super(props);
        this._timer = null;

        // const socketUrl = `${process.env.REACT_APP_BASE_API_URL}/admin`;
        // socket = ioClient(socketUrl, {
        //   path: '/socket.io/',
        //   query: `key=${encodeURIComponent(process.env.REACT_APP_BASE_API_KEY)}`,
        //   "connect timeout": 5000
        // });
        // socket.on("number-of-clients", clients => {
        //     this.props.updateConnectedClients(clients);
        // });
    }

    componentDidMount() {
        this.props.fetchEvents();
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps, this.props);
    }

    componentWillUnmount() {
        clearInterval(this._timer);
    }

    render() {
        const {
            pastEvents,
            currentEvents,
            futureEvents,
            connectedClients
        } = this.props;
        const pEvents = pastEvents.map(event => {
            return (
                <Link key={event.externalId} to={`/events/${event.collectorId}`} >
                <Card outline color="danger">
                    <CardHeader>
                        {moment(event.dateFrom).format("YYYY-MM-DD HH:mm")}
                    </CardHeader>
                    <CardBody>
                        <CardTitle>{event.eventName}</CardTitle>
                        <CardSubtitle>{event.trackName}</CardSubtitle>
                        <h2 className="text-center">
                            {connectedClients[event.externalId]}
                        </h2>
                        <Badge
                            color={event.live ? "success" : "danger"}
                        >
                            Live
                        </Badge>
                    </CardBody>
                    <CardFooter>
                        {moment(event.dateTo).format("YYYY-MM-DD HH:mm")}
                    </CardFooter>
                </Card>
                </Link>              
            );
        });
        const cEvents = currentEvents.map(event => {
            return (
                <Link key={event.externalId} to={`/events/${event.collectorId}`} >

                <Card outline color="danger">
                    <CardHeader>
                        {moment(event.dateFrom).format("YYYY-MM-DD HH:mm")}
                    </CardHeader>
                    <CardBody>
                        <CardTitle>{event.eventName}</CardTitle>
                        <CardSubtitle>{event.trackName}</CardSubtitle>
                        <h2 className="text-center">
                            {connectedClients[event._id]}
                        </h2>

                        <Badge
                            color={event.live ? "success" : "danger"}
                        >
                            Live
                        </Badge>
                    </CardBody>

                    <CardFooter>
                        {moment(event.dateTo).format("YYYY-MM-DD HH:mm")}
                    </CardFooter>
                </Card>
                </Link>   
            );
        });
        const fEvents = futureEvents.map(event => {
            return (
                <Link key={event.id} to={`/events/${event.collectorId}`} >

                <Card outline color="danger">
                    <CardHeader>
                        {moment(event.dateFrom).format("YYYY-MM-DD HH:mm")}
                    </CardHeader>
                    <CardBody>
                        <CardTitle>{event.eventName}</CardTitle>
                        <CardSubtitle>{event.trackName}</CardSubtitle>
                        <h2 className="text-center">
                            {connectedClients[event._id]}
                        </h2>
                        <Badge
                            color={event.live ? "success" : "danger"}
                        >
                            Live
                        </Badge>
                    </CardBody>
                    <CardFooter>
                        {moment(event.dateTo).format("YYYY-MM-DD HH:mm")}
                    </CardFooter>
                </Card>
                </Link>   
            );
        });
        
            
        return (
            <div>
                <h1>Current Events</h1>
                <CardColumns>{cEvents}</CardColumns>
                <h1>Future Events</h1>
                <CardColumns>{fEvents}</CardColumns>
                <h1>Past Events</h1>
                <CardColumns>{pEvents}</CardColumns>
            </div>
            
        );
    }
}

export default Events;
