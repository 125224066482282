import queryString from "query-string";
import fetch from "isomorphic-fetch";
import firebase from 'firebase/app';


export const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    return response.json().then(res => {
        const err = new Error(res.message);
        err.statusCode = res.statusCode;
        err.error = res.error;
        err.meta = res.meta;
        return Promise.reject(err);
    });
};
// https://medium.com/@Mikepicker/authenticate-axios-requests-using-firebase-oauth-tokens-5c11d1482c17

export const parseJSON = response => response.json();

/* eslint-disable no-underscore-dangle */
class Api {
    constructor() {
        this.baseUrl =
            process.env.NODE_ENV !== "production"
                ? "http://localhost:3001/v1"
                // ? "https://api.scorit.se/v1"
                : `${process.env.REACT_APP_BASE_API_URL}/v1`;
    }

    // eslint-disable-next-line class-methods-use-this
    create(options) {
        return new Api(options);
    }

    async fetchEvents() {
        const idToken = await firebase.auth().currentUser.getIdToken();
        return fetch(`${this.baseUrl}/events?admin=true`, {
            headers: {
                'Authorization': 'Bearer ' + idToken
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }
            
        })
        .then(checkStatus)
        .then(parseJSON);
    }
    fetchEventsSorted() {
        const query = queryString.stringify({ sorted: true }) || "";
        return fetch(`${this.baseUrl}/events?${query}`)
            .then(checkStatus)
            .then(parseJSON);
    }
    async fetchEvent(eventId) {
        const idToken = await firebase.auth().currentUser.getIdToken();

        return fetch(`${this.baseUrl}/raceadmin/${eventId}`, {
                headers: {
                    'Authorization': 'Bearer ' + idToken
                }
            })
            .then(checkStatus)
            .then(parseJSON);
    }
    async addEvent(event) {
        const idToken = await firebase.auth().currentUser.getIdToken();

        return fetch(`${this.baseUrl}/raceadmin/events`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + idToken,
                Accept: "application/json",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(event)
        })
            .then(checkStatus)
            .then(parseJSON);
    }


}

export default new Api();
