import React, { Component } from "react";
import EventDetails from './../eventDetails/EventDetails';
import {
    Container,
    Row,
    Form,
    Col,
    Label,
    Input,
    Button,
    FormGroup
} from "reactstrap";

class CreateEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventName: "Test",
            trackName: "Malmö Automobilklubb",
            dateFrom: "",
            dateTo: "",
            sponsor: "finderab",
            type: "kart",
            resultsId: "",
            liveStreamLink: "",
            timeTable: `08:00	Junior 125	Träning	10 min
            -	Mini	Träning	10 min
            -	Ok	Träning	10 min
            -	DD2	Träning	10 min
            -	Cadetti	Träning	10 min
            -	Junior 125	Träning	10 min
            -	Mini	Träning	10 min
            -	Ok	Träning	10 min
            -	DD2	Träning	10 min
            -	Cadetti	Träning	10 min
            11:00	Junior 125	Tidskörning	10 min
            -	Junior 125	Tidskörning	10 min
            -	Mini	Tidskörning	10 min
            -	Ok	Tidskörning	10 min
            -	DD2	Tidskörning	10 min
            -	Uppvisning	Cadetti	4
            13:00	Heat A-B	Junior 125	9
            -	Heat 1	Mini	9
            -	Heat 1	Ok	9
            -	Heat 1	DD2	9
            -	Heat B-C	Junior 125	9
            -	Uppvisning	Cadetti	4
            -	Heat 2	Mini	9
            -	Heat 2	Ok	9
            -	Heat 2	DD2	9
            -	Heat A-C	Junior 125	9
            -	Uppvisning	Cadetti	4
            15:00	Förfinal	Mini	9
            -	Förfinal	Ok	11
            -	Förfinal	DD2	11
            -	Förfinal Gr1	Junior 125	11
            -	Förfinal Gr2	Junior 125	11
            -	Uppvisning	Cadetti	4
            17:00	Final	Mini	12
            -	Final	Ok	14
            -	Final	DD2	14
            -	Final	Junior 125	14`
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.resetCreatedEvent(this.state);
    }

    handleChange = async event => {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value
        });
        console.log(this.state);
    };

    submitForm(e) {
        e.preventDefault();
        this.props.addEvent(this.state);
        console.log("State:", this.state);
    }
    render() {
        const {
            eventName,
            trackName,
            dateFrom,
            dateTo,
            sponsor,
            type,
            resultsId,
            liveStreamLink,
            eventPage
        } = this.state;
        const {createdEvent} = this.props;

        return (
            <div className="site-bg">
                <Container>
                    <Form className="form" onSubmit={e => this.submitForm(e)}>
                        {" "}
                        <Row>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="exampleEmail">Event name</Label>
                                    <Input
                                        type="text"
                                        name="eventName"
                                        id="Event"
                                        placeholder="Event name"
                                        value={eventName}
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="TrackName">Track</Label>
                                    <Input
                                        type="select"
                                        name="trackName"
                                        id="TrackName"
                                        value={trackName}
                                        placeholder="Track name"
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    >
                                        <option>Malmö automobilklubb</option>
                                        <option>Axamoring</option>
                                        <option>Helsingborgs kk</option>
                                        <option>Kalmar mk</option>
                                        <option>Ks klippan</option>
                                        <option>Kristianstads kk</option>
                                        <option>Uddevalla</option>
                                        <option>Wäxjö ms</option>
                                        <option>Hagaslätt</option>
                                        <option>
                                            Borlänge amsbergs motorstadion
                                        </option>
                                        <option>Team 13</option>
                                        <option>Pori</option>
                                        <option>Kouvola</option>
                                        <option>Skövde MK</option>
                                        <option>Lidköpings KK</option>
                                        <option>Ruuhimäki</option>
                                        <option>RC-Västerås</option>
                                        <option>Kangasala</option>
                                        <option>Tuvängen</option>
                                        <option>Aravete</option>
                                        <option>Honkajoki</option>
                                        <option>Vihti</option>
                                        <option>Gälleråsen</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="DateFrom">Date from</Label>
                                    <Input
                                        type="datetime-local"
                                        name="dateFrom"
                                        id="DateFrom"
                                        value={dateFrom}
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="DateTo">Date to</Label>
                                    <Input
                                        type="datetime-local"
                                        name="dateTo"
                                        id="DateTo"
                                        value={dateTo}
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="exampleEmail">Sponsor</Label>
                                    <Input
                                        type="select"
                                        name="sponsor"
                                        id="Sponsor"
                                        value={type}
                                        placeholder="Sponsor"
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    >
                                        <option>finderab</option>
                                        <option>radneab</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="exampleEmail">Type</Label>
                                    <Input
                                        type="select"
                                        name="type"
                                        id="Type"
                                        value={sponsor}
                                        placeholder="Type"
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    >
                                        <option>kart</option>
                                        <option>mini-racing</option>
                                        <option>rally-cross</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="EventEnd">Resultatlänk</Label>
                                    <Input
                                        type="text"
                                        name="resultsId"
                                        id="EventEnd"
                                        value={resultsId}
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="EventEnd">Live link</Label>
                                    <Input
                                        type="text"
                                        name="liveStreamLink"
                                        id="LiveLink"
                                        value={liveStreamLink}
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="EventPage">Event Page url</Label>
                                    <Input
                                        type="text"
                                        name="eventPage"
                                        id="EventPage"
                                        value={eventPage}
                                        onChange={e => {
                                            this.handleChange(e);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button>Submit</Button>
                    </Form>
                    <EventDetails event={createdEvent} />
                </Container>
            </div>
        );
    }
}

export default CreateEvent;
