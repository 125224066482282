import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from "./store";
import App from "./containers/app";

import "bootstrap/dist/css/bootstrap.min.css";
import "./vendor/font-awesome/css/font-awesome.css";
import "./style/main.scss";

import { unregister } from "./registerServiceWorker";
import Auth from './authentication/Auth';

const target = document.querySelector("#root");

render(
    <Auth>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div>
                    <App />
                </div>
            </ConnectedRouter>
        </Provider>
    </Auth>
    ,
    target
);
unregister();
